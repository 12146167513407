<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="800"
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ title }}</span>
            </v-card-title>

            <v-stepper
                v-model="currentStep"
                vertical
            >
                <div
                    v-for="(item, index) in htmlText"
                    :key="index"
                >
                    <v-stepper-step
                        :step="index + 1"
                        :complete="currentStep > index + 1"
                    >
                        {{ item.title }}
                    </v-stepper-step>

                    <v-stepper-content :step="index + 1">
                        <v-card-text class="mb-5 mx-0 px-0">
                            <div v-html="item.body" />
                        </v-card-text>

                        <v-btn
                            class="mr-2 px-2"
                            color="primary"
                            @click.native="accepted(index, item.id)"
                        >
                            {{ index + 1 === htmlText.length ? $t('common.finish') : $t('common.continue') }}
                        </v-btn>
                        <v-btn
                            class="px-1"
                            text
                            @click.native="rejected"
                        >
                            {{ $t('common.cancel') }}
                        </v-btn>
                    </v-stepper-content>
                </div>
            </v-stepper>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from '@/plugins/axios';

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        dialog: {
            type: Boolean,
            default: false
        },
        htmlText: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            read: false,
            currentStep: 1
        };
    },
    computed: {
        ...mapGetters('languages', ['getLang'])
    },
    methods: {
        ...mapActions(['signOut']),
        getTranslatedHtml(index) {
            if (this.htmlText?.length) {
                const htmlText = this.htmlText[index];

                if (this.getLang === 'es') {
                    return htmlText.body;
                }

                return htmlText.en.body;
            }

            return '';
        },
        async accepted(index, termId) {
            this.currentStep = index + 2; // because currentStep start in 1 and index start in 0
            // this.$emit('accepted', index);

            // SI es el ultimo paso, se cierra la ventana despues de 2 segundos
            if (this.currentStep > this.htmlText.length) {
                setTimeout(() => {
                    this.$emit('closeDialog');
                }, 2000);
            }

            try {
                /* const acceptTerm = */ await axios.post('legal/acceptTerm', { term_id: termId });
            } catch (error) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorSavingData'));

                if (error.response) {
                    console.log(error.response.data.error); // "invalid_credentials"
                    console.log(error.response.status);
                }
            }
        },
        rejected() {
            this.signOut();
            this.$router.push('/app/sessions/sign-in');
            // this.currentStep = index + 1
            // this.$emit('rejected', index)
        }
    }
};
</script>

<style lang="scss">
div.scrollable-container {
    height: 100px;
    width: 100%;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        overflow-y: scroll;
        height: 90%;
    }
}
</style>
